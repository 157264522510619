input:focus, input:active, .form-control:focus, .form-control:active {
    outline: none !important;
    overflow: hidden;
}

.bottomHeaderrr {
    background-color: #91D8F7;
    width: 90%;
    margin: 0 auto;
    border-radius: 20px;
    margin-top: 15px;
    padding-bottom: 4px;
}

.DeskContentDivv {
    padding-left: 20px;
    padding-right: 20px;
}

.blackDivBtnss {
    width: 35px;
    margin-top: 9px;
}

.blackConnectTxtSwap {
    margin-top: 10px;
    text-align: center;
    margin-left: 0px;
    font-size: 21px;
    color: black;
    font-weight: bold;
    /* font-weight: 1000;
    letter-spacing: 1px; */
    position: relative;
    top: 5px;
}

.hrSwap {
    width: 100%;
    margin-top: 0px;
    border: 2px solid #0367FE;
    background-color: #0367FE;
}

.colorGreyy {
    color: black;
    text-align: end;
    font-size: 15px;
    font-weight: bold;
}

.whiteDivv {
    background-color: white;
    color: black;
    border: 3px solid black;
    border-radius: 10px;
    padding: 0px 0;
}

.darkBlueDivv {
    background-color: #182E2C;
    border: 3px solid #182E2C;
    border-radius: 10px;
    padding: 0px 0;
}

.optionStakeBtnn {
    background-color: #182E2C;
    border-radius: 10px;
    color: white;
    border: none;
    height: 80%;
    width: 70%;
    margin-top: 1px;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    top: -4px;
}

.whiteStakeAmountt h5 {
    font-weight: bold;
    text-align: end;
    font-size: 14px;
    margin-top: 7px;
}

input[type="email"]::placeholder {

    /* Firefox, Chrome, Opera */
    color: #099933;
}

input[type="email"]:-ms-input-placeholder {

    /* Internet Explorer 10-11 */
    color: #099933;
}

input[type="email"]::-ms-input-placeholder {

    /* Microsoft Edge */
    color: #099933;
}


input[type="stakeTxt"]::placeholder {

    /* Firefox, Chrome, Opera */
    color: #0367FE;
}

input[type="stakeTxt"]:-ms-input-placeholder {

    /* Internet Explorer 10-11 */
    color: #0367FE;
}

input[type="stakeTxt"]::-ms-input-placeholder {

    /* Microsoft Edge */
    color: #0367FE;
}


input[type="hello"]::placeholder {

    /* Firefox, Chrome, Opera */
    color: #1dccff;
}

input[type="hello"]:-ms-input-placeholder {

    /* Internet Explorer 10-11 */
    color: #1dccff;
}

input[type="hello"]::-ms-input-placeholder {

    /* Microsoft Edge */
    color: #1dccff;
}

input, .form-control, .form-control:active, .form-control:focus {
    outline: none !important;
}

.swapSetAmount {
    text-align: end;
    font-weight: bold;
    color: #099933;
    border: none;
    font-size: 15px;
    border-top: none;
}

.extraFtpTxtt h5 {
    font-weight: bold;
    text-align: start;
    color: #F4F401;
    font-size: 12px;
    margin-top: 7px;
}

.MaxTxtt {
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    margin-left: 7px;
    position: relative;
    top: 15px;
}

.colorGreen {
    color: #099933;
}

.twelveMntTxtt {
    margin-top: -20px;
}

.stakeBtnss {
    background-color: #111224;
    border-radius: 10px;
    color: white;
    border: none;
    height: 40px;
    width: 160px;
    font-size: 16px;
    font-weight: bold;
}

/* receipt */

.wholePage {
    background-color: #91D8F7;
    /* width: 100%;
    height: 100vh; */
    overflow: hidden;
    /* margin-top: -10px; */
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.receiptMain {
    background-color: white;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    /* padding-top: 20px; */
    text-align: center;
}

.receiptHeading {
    background-color: #1761fb;
    padding-top: 20px;
    font-weight: bold;
    padding-bottom: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.fontSize {
    font-size: 15px;
}

.receiptBtn {
    background-color: #111224;
    border-radius: 10px;
    color: white;
    border: none;
    height: 40px;
    width: 160px;
    font-size: 16px;
    font-weight: bold;
}

.swapTOkenMargins {
    margin-left: 11px;
    margin-right: 11px;
}

.swapTokenTOken {
    margin-left: -10px;
}

.width30 {
    width: 30px;
}

.width40 {
    width: 40px;
}
.stakeBlance{
    font-size: 14px !important;
}

@media only screen and (max-width: 2000px) and (min-width: 1000px) {
    .swapTOkenMargins {
        margin-left: 5px;
        margin-right: 9px;
    }

    .swapTokenTOken {
        margin-left: -10px;
    }
}

@media only screen and (max-width: 1763px) and (min-width: 1000px) {
    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 8px;
    }

    .swapTokenTOken {
        margin-left: -10px;
    }
}

@media only screen and (max-width: 1550px) and (min-width: 1000px) {
    .settingIcon {
        margin-left: 0px;
    }

    .bigScreenIssue {
        margin-left: 15px !important;
    }
}

@media only screen and (max-width: 1700px) and (min-width: 1000px) {
    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 6px;
    }

    .width40 {
        width: 35px !important;
    }

    .swapTokenTOken {
        margin-left: -7px;
    }

    .headerNav, .MainDivMain, .footerInerDiv {
        width: 75%;
    }
}

@media only screen and (max-width: 1500px) and (min-width: 1000px) {
    .width40 {
        width: 30px !important;
    }

    .dropDOwn {
        width: 10px;
    }

    .swapTokenTOken {
        margin-left: -5px;
    }

    .headerNav, .MainDivMain, .footerInerDiv {
        width: 80%;
    }
}

@media only screen and (max-width: 1350px) and (min-width: 1000px) {
    .width40 {
        width: 25px !important;
    }

    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 4px;
        font-size: 13px;
    }

    .swapTokenTOken {
        margin-left: -2px;
    }

    .dropDOwn {
        width: 10px;
    }

    .swapAmountTxt {
        font-size: 13px !important;
    }
}

@media only screen and (max-width: 1300px) and (min-width: 1000px) {
    .swapOutput {
        width: 94px !important;
    }
}

@media only screen and (max-width: 1274px) and (min-width: 1000px) {
    .headerNav, .MainDivMain, .footerInerDiv {
        width: 85%;
    }
}

/* @media only screen and (max-width: 1403px) and (min-width: 1000px) {
    .width40 {
        width: 22px !important;
    }
    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 4px;
        font-size: 12px;
    }
    .dropDOwn {
        width: 8px;
    }
}

@media only screen and (max-width: 1365px) and (min-width: 1000px) {
    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 4px;
    }

    .recipt1ConfirmTxt {
        font-size: 17px;
    }

    .viewOnExplorerTxt {
        font-size: 18px !important;
    }

    .width40 {
        width: 35px !important;
    }
} */

@media only screen and (max-width: 1275px) and (min-width: 1000px) {
    .blackConnectTxtSwap {
        font-size: 22px !important;
    }
}

@media only screen and (max-width: 1227px) and (min-width: 1000px) {
    .width40 {
        width: 25px;
    }

    .graphDiv {
        width: 100% !important;
    }

    .functionalityDiv {
        width: 120% !important;
        margin-left: -70px;
    }

    .graphUpDown {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1033px) and (min-width: 1000px) {
    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 4px;
        font-size: 12px;
    }
}

/* @media (min-width: 1000px) {
    .receiptMain {
        background-color: #F2F2F2;
        width: 50%;
        margin-top: 60px;
        border: 50px solid white;
        border-radius: 10px;
    }
    .wholePage {
        background-color: #111224;
    }
} */
@media (max-width: 500px) {
    .swapTOkenMargins {
        margin-left: 4px;
        margin-right: 4px;
    }

    .hrSwap {
        border-width: 1px;
    }

    .navBarConnectBTn {
        font-weight: normal;
    }

    .swapAmountInput {
        background-color: transparent;
    }

    .swapAmountBox {
        margin-bottom: -5px;
    }
}

@media (min-width: 400px) {
    .marginMinus5 {
        margin-top: 5px !important;
    }
}

.assetsBottomHeandler {
    color: blue;
    margin-top: 26px;
}

.assetsActivityTxtActive {
    text-align: center;
    color: black;
    font-size: 18px;
    font-weight: bold;
}

#font18 {
    font-size: 18px !important;
}

@media (max-width: 400px) {
    .blackConnectTxtSwap {
        font-size: 19px;
    }

    .colorGreyy {
        font-size: 13px;
    }

    .balancE {
        margin-bottom: -1px;
    }

    .marginMinus5 {
        margin-top: 2px !important;
    }

    .assetsBottomHeandler {
        color: blue;
        margin-top: 15px;
    }

    .firstLightInfoMob {
        margin-top: 1px !important;
    }

    .blackDivBtnss {
        width: 30px;
        margin-top: 9px;
    }

    .hrSwap {
        margin-top: -7px;
    }

    .bottomBtns {
        margin-top: 7px !important;
    }

    .bottomBtns h1 {
        font-size: 16px !important;
    }
}

@media (max-width: 378px) {
    .dropDOwn {
        width: 10px;
    }

    .optionStakeBtnn {
        font-size: 13px;
    }

    .settingIcon {
        margin-left: -2px !important;
    }

}

@media (max-width: 357px) {
    .swapTOkenMargins {
        margin-left: 2px;
        margin-right: 2px;
    }
}
.networksDivvv {
    /* border: 1px solid grey; */
    width: 90%;
    /* background-color: #111224; */
    margin: 0 auto;
    padding-bottom: 10px;
    border-radius: 20px;
    padding-top: 10px;
    margin-top: 25px;
    font-size: 15px;
    text-align: start;
}

@media only screen and (max-width: 1703px) and (min-width: 1000px) {
    .whatIsStakingHR{
        margin-top: 72px !important;
    }
}
@media only screen and (max-width: 1535px) and (min-width: 1000px) {
    .whatIsStakingHR{
        margin-top: 43px !important;
    }
}
@media only screen and (max-width: 1281px) and (min-width: 1000px) {
    .whatIsStakingHR{
        margin-top: 16px !important;
    }
}
@media only screen and (max-width: 1227px) and (min-width: 1000px) {
    .whatIsStakingHR{
        margin-top: 40px !important;
    }
}
@media only screen and (max-width: 1084px) and (min-width: 1000px) {
    .whatIsStakingHR{
        margin-top: 16px !important;
    }
}

@media (max-width: 410px) {
    .networksDivvv {
        font-size: 14px;
    }
}@media (max-width: 380px) {
    .networksDivvv {
        font-size: 13px;
    }
}
.blackConnectTxt4 {
    font-size: 20px;
    margin-top: 3px;
    text-align: center;
    margin-left: 0px;
    font-weight: bold;
    /* color: #1DCCFF; */
    color: black;
}

.swapTknsHeadi {
    text-align: center;
    font-size: 15px;
    color: white;
    margin-top: 15px;
    margin-bottom: 18px;
}

.selectSwapToken {
    margin-top: 14px;
}

.swapTokens {
    margin-bottom: 10px;
}

@media (min-width: 1000px) {
    .swapTokens {
        margin-bottom: 17px;
    }
}

@media (max-width: 400px) {

    .selectSwapToken {
        margin-top: 20px;
    }

    .swapTokens {
        margin-bottom: 8px;
    }

    .swapTknsHeadi {
        font-size: 14px;
    }

    .blackConnectTxt4 {
        font-size: 19px;
    }
}

@media (max-width: 375px) {

    .selectSwapToken {
        margin-top: 10px;
    }

    .swapTknsHeadi {
        font-size: 14px;
    }

    .blackConnectTxt4 {
        font-size: 19px;
    }
}

@media (max-height: 687px) {
    .selectSwapToken{
        margin-top: 20px;
    }
}
@media (max-height: 667px) {
    .selectSwapToken{
        margin-top: 15px;
    }
}
@media (max-height: 657px) {
    .selectSwapToken{
        margin-top: 12px;
    }
}
a:hover{
    text-decoration: none;
}

.blackConnectTxt3 {
    text-align: center;
    margin-left: 0px;
    font-size: 22px;
    font-weight: bold;
    color: #1DCCFF;
}

.greyDiv3 {
    /* background-color: #2B2B2B; */
    background-color: #111518;
    width: 90%;
    margin: 0 auto;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 1px;
    border-bottom: 1px solid #2B2B2B;
}

.greyDiv4 {
    /* background-color: #2B2B2B; */
    background-color: #111518;
    width: 90%;
    margin: 0 auto;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 1px;
    border-bottom: 1px solid #2B2B2B;
}

.WhichWalletPara {
    background-color: red;
}

.WhichWalletPara {
    font-size: 13px;
}

.bottomHeaderLogo1 {
    float: right;
    margin-right: -15px;
    margin-top: 8px;
}

.bottomHeaderLogo2 {
    float: right;
    margin-right: -15px;
    margin-top: -8px;
}

.marginLeft1 {
    margin-left: -5px;
}

.marginLeft2 {
    margin-left: -10px;
}

.marginRight {
    margin-right: -20px;
}

.fairtraderConnectWalletTxt {
    font-size: 16px;
}

@media (max-width: 400px) {
    .blackConnectTxt3 {
        font-size: 18px;
        margin-top: 1px;
        margin-bottom: 10px;
    }
}

@media (min-width: 1000px) {
    .greyDiv3 {
        background-color: #111518;
        width: 90%;
        margin: 0 auto;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 18px;
        padding-bottom: 18px;
        border-radius: 1px;
        border-bottom: 1px solid #2B2B2B;
    }
}
.stakedTknsHeadi{
    text-align: center;
    font-size: 15px;
    color: #00ccff;
    margin-top: 5px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1450px) and (min-width: 1000px) {
    .stakedTknsHeadi{
        font-size: 15px !important;
    }
}
@media (max-width: 390px) {
    .mobileRectipUnstakeTxt{
        font-size: 16px !important;
    }
}
@media (max-width: 360px) {
    .mobileRectipUnstakeTxt{
        font-size: 15px !important;
    }
}
/* Scroll Bar                     scroll bar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(131, 131, 131);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 99, 99);
  /* background: #16FE00;  */
}

/* ::-webkit-scrollbar {
  width: 10px; */
/* border: 5px solid #C1C1C1; */
/* border: 5px solid black;
} */

/* ::-webkit-scrollbar-thumb {
  background-color: white;
  background-clip: padding-box; */
/* border: 1px solid #71777B; */
/* } */

/* ::-webkit-scrollbar-track {
  background-color: #C1C1C1;
  border: 1px solid #999999;
} */
/* Buttons */

/* ::-webkit-scrollbar-button:single-button {
  background-color: white;
  display: block;
  border-style: solid;
  height: 8px;
  width: 16px;
} */
/* Up */
/* ::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0px 5px 7px 5px;
  border-color: transparent transparent #313131 transparent;
  border-radius: 2px;
} */

/* ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
} */
/* Down */
/* ::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 7px 5px 0px 5px;
  border-color: #313131 transparent transparent transparent;
  border-radius: 2px;
} */

/* ::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
} */

.stakedTokenBnbTxt {
  text-align: start !important;
}

.stakedTokenBnbTxtValue {
  text-align: end !important;
}

button:active, button:focus {
  outline: none;
}

.MainDivMob, .MainDivDesktop {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.row {
  margin: 0 auto;
}

.blackWholeDiv {
  /* height: 37.5vh; */
  height: 35vh;
  background-color: #000000;
  color: white;
  padding-top: 10px;
}

.fairtraderLogo {
  width: 140px;
}

.bottomheaderDrop {
  margin-left: 10px;
  white-space: nowrap;
  color: #0EC70D;
}

.headerLinks {
  float: right;
}

.headerBtns {
  background-color: transparent;
  border: 2px solid #1DCCFF;
  border-radius: 5px;
  font-size: 15px;
  margin-right: 5px;
  position: relative;
  top: -2px;
  cursor: pointer;
}

.sndHeaderBtn {
  padding-top: 3px;
  padding-bottom: 2px;
}

.sndHeaderBtn img {
  margin-left: 3px;
  margin-right: 3px;
}

.myAssetsHeading {
  font-size: 15px;
}

/* .sndHeaderBtn::before {
  content: url("./Images/networkImg.png");
  transform: scale(2);
  position: relative;
  top: 3px;
  right: 3px;
}

.sndHeaderBtn::after {
  content: url("./Images/networkDropdown.png");
  position: relative;
  left: 3px;
  top: -5px;
} */

/* bottomHeader */
.bottomHeader {
  margin-top: 20px;
}

.bottomHeader p {
  text-align: center;
}

.bottomHeaderLogo {
  float: right;
  margin-right: -15px;
  width: 32px;
  margin-top: 3px;
}

.colorBlueBold {
  font-weight: bold;
  color: #1DCCFF;
}

.graphPhoto {
  margin-top: 110px;
}

.graph img {
  /* width: 480px; */
  width: 100%;
  height: 23%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

/* blueDiv */
.blueDiv {
  height: 65vh;
  color: white;
  background-color: #0561FB;
  /* background-color: #380814; */
  padding-top: 5px;
}

.MainLinks {
  width: 80%;
  margin: 0 auto;
  margin-bottom: -10px;
}

.mainLinksPics {
  display: inline;
  margin: 0 auto;
}

.mainLinksPics1 {
  width: 43px;
}

.mainLinksPics2 {
  width: 48px;
}

.blueBlackMainDiv {
  background-color: black;
  margin: 0 auto;
  width: 85%;
  border-radius: 20px;
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.blackDivBtns {
  width: 37px;
}

.blackDivBtnsIst {
  width: 30px !important;
}

.blackDivBtnsIstImg {
  width: 30px !important;
}

.blackConnectTxt {
  text-align: center;
  margin-left: 0px;
  font-size: 21px;
  font-weight: bold;
  color: #1DCCFF;
}

.hr {
  width: 100%;
  margin-top: -1px;
  border: 1px solid #0561FB;
  background-color: #0561FB;
}

.greyDiv {
  /* background-color: #2B2B2B; */
  background-color: #262626;
  width: 85%;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  margin-top: 15px;
}

.greyDiv h4 {
  font-size: 17px;
}

.blackBottomBtn {
  text-align: center;
  margin-top: 10px;
  font-size: 23px;
  color: #1DCCFF;
}

.blackBottomBtn button {
  background-color: #2223DE;
  padding: 5px 20px;
  border: 3px solid #32619E;
  font-weight: bold;
  border-radius: 10px;
}

/* media queries */
@media only screen and (max-width: 1000px) and (min-width: 450px) {
  .blackWholeDiv {
    height: 39vh !important;
  }

  .blueDiv {
    height: 61vh !important;
  }
}

/* media queries */
@media only screen and (max-width: 400px) and (min-width: 380px) {
  .blackWholeDiv {
    height: 36.5vh !important;
  }

  .blueDiv {
    height: 63.5vh !important;
  }
}
@media only screen and (max-width: 380px) and (min-width: 350px) {
  .blackWholeDiv {
    height: 34vh !important;
  }

  .blueDiv {
    height: 66vh !important;
  }
}

@media (max-width: 464px) {
  .greyDiv h4 {
    font-size: 15px;
  }

  .blackBottomBtn button {
    font-size: 19px;
  }

  .headerBtns {
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .MainLinks {
    margin-bottom: -10px;
  }

  .blueDiv {
    padding-top: 5px;
  }
}

@media (max-width: 430px) {

  .settingIcon {
    margin-left: -5px;
  }
}

@media (max-width: 416px) {
  .greyDiv h4 {
    font-size: 14px;
  }

  .bottomheaderDrop {
    margin-left: 15px;
  }

  .MainLinks {
    width: 80%;
  }

  .mainLinksPics1 {
    width: 40px;
  }

  .mainLinksPics2 {
    width: 43px;
  }

}

@media (max-width: 397px) {
  .fairtraderLogo {
    width: 110px;
  }

  .sndHeaderBtn img {
    width: 16px;
  }

  .blackDivBtnsIstImg {
    margin-left: 10px !important;
  }

  .headerBtns {
    font-size: 12px;
  }

  .toggleBtn {
    width: 30px;
  }

  .greyDiv h4 {
    font-size: 13px;
  }

  .settingIcon {
    margin-left: -10px;
  }

  .bottomHeaderLogo {
    width: 28px;

    float: left;
    margin-left: -10px;
  }

  .bottomHeaderTxt {
    font-size: 12px;
  }

  .marginLeft {
    margin-left: -15px;
  }

  .marginLeftt {
    margin-left: -5px;
  }

  .MainLinks {
    width: 80%;
  }

  .whatStakePara1 {
    font-size: 13px;
  }

}

@media (max-width: 382px) {

  .headerBtns {
    font-size: 11px;
  }

  .newStakeWalCont {
    font-size: 16px !important;
  }
}

@media (max-width: 370px) {
  .newStakeWalCont {
    font-size: 15px !important;
  }

  .fairtraderLogo {
    width: 100px;
  }

  .sndHeaderBtn img {
    width: 14px;
  }

  .headerBtns {
    font-size: 11px;
  }

  .toggleBtn {
    width: 27px;
  }

  .settingIcon {
    margin-left: -15px;
  }

  .greyDiv h4 {
    font-size: 12px;
  }

  .whatStakePara1 {
    font-size: 12px;
  }

}

@media (max-width: 360px) {

  .headerBtns {
    font-size: 10px;
  }
}

@media (max-width: 343px) {
  .fairtraderLogo {
    width: 100px;
  }

  .sndHeaderBtn img {
    width: 13px;
  }

  .headerBtns {
    font-size: 10px;
  }

  .toggleBtn {
    width: 25px;
  }
}

@media (min-width: 2200px) {
  .singleBoxTxtLine span {
    font-size: 30px !important;
  }
}
@media (max-width: 2200px) {
  .singleBoxTxtLine span {
    font-size: 27px !important;
  }
}

@media (max-width: 2003px) {
  .singleBoxTxtLine span {
    font-size: 25px !important;
  }
}

@media (max-width: 1900px) {
  .singleBoxTxtLine span {
    font-size: 23px !important;
  }
}

@media (max-width: 1765px) {
  .singleBoxTxtLine span {
    font-size: 21px !important;
  }
}

@media (max-width: 1560px) {
  .singleBoxTxtLine span {
    font-size: 19px !important;
  }
}

@media (max-width: 1355px) {
  .singleBoxTxtLine span {
    font-size: 17px !important;
  }
}

@media (max-width: 1175px) {
  .singleBoxTxtLine span {
    font-size: 15px !important;
  }
}

@media (max-width: 1075px) {
  .singleBoxTxtLine span {
    font-size: 13.5px !important;
  }
}


@media (max-width: 1000px) {
  .MainDivDesktop {
    display: none;
  }

  .MainDivMob {
    display: initial;
  }
}


@media (min-width: 1000px) {
  .MainDivDesktop {
    display: initial;
  }

  .MainDivMob {
    display: none;
  }

  .MainDivDesktop {
    height: 40vh;
    background-color: #000000;
    color: white;
    padding-top: 10px;
  }

  .headerNav {
    width: 71%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .navConnectBtn {
    width: 30px !important;
    margin-right: 10px !important;
    margin: 3px 0px !important;
  }

  .navConnectSNDBtn {
    margin-right: 10px !important;
    margin: 2px 0px !important;
  }

  .navConnectTxt {
    font-size: 18px !important;
    margin-left: 5px;
    margin-right: 10px;
  }

  .navFairtraderLogo {
    width: 170px;
  }

  .MainDivMain {
    width: 71%;
    margin-top: 30px;
  }

  .sndHeaderBtn img {
    margin-left: 5px !important;
    margin-right: 5px !important;
    width: 25px;
  }

  .navBarDisConnectBTn {
    font-weight: bold;
    color: #1DCBFE;
    margin-right: 25px;
    margin-left: 25px;
    font-size: 16px;
  }

  .navBarConnectBTn {
    color: #E0E102;
    margin-right: 28px;
    margin-left: 28px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .navBarNetworkBTn {
    color: #1DCBFE;
    font-weight: bold;
    margin-right: 35px;
    margin-left: 35px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .navBarDisNetworkBTn {
    color: #1DCBFE;
    font-weight: bold;
    margin-right: 16px;
    margin-left: 15px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .navHeaderBtnDiv {
    border-radius: 10px !important;
    margin-right: 15px;
  }

  .headerBtnIst {
    padding-left: 5px;
  }

  .connectTxt {
    padding-right: 12px;
  }

  .networkBtn {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .toggleBtn {
    width: 45px;
  }

  .sndHeaderBtn {
    background-color: #111224 !important;
  }

  .networksTxt {
    position: relative;
    top: 2px;
  }

  .desktopBlueDiv {
    background-color: #2D7EF7;
    width: 100%;
  }

  .desktopWhiteMainDiv {
    background-color: #fff;
    width: 100%;
  }

  .desktopWhiteDiv {
    background-color: #fff;
    width: 90%;
    margin: 0 auto;
    color: #13579F;
    padding-top: 25px;
  }

  .desktopWhiteDiv h1 {
    font-size: 28px;
  }

  .desktopWhiteDiv p {
    font-size: 16px;
  }

  .hrr {
    width: 99.5%;
    position: relative;
    top: 10px;
    border: 4px solid #224161;
    background-color: #224161;
  }

  .HelpTxt {
    display: inline;
    color: #00FFFF;
  }

  .VideoTxt {
    margin-left: 30px;

  }

  .bottomHeader {
    background-color: black;
    width: 90%;
    height: 480px;
    border-radius: 20px;
    padding-top: 20px;
  }

  .graphDesktop img {
    /* width: 480px; */
    width: 87%;
    height: 380px;
    position: absolute;
    left: 45%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);

  }

  .bottomHeaderLogo {
    width: 40px;
  }

  .bottomHeaderTxt {
    font-size: 14px;
  }

  .marginLeft {
    margin-left: 0px;
  }

  .marginLeftt {
    margin-left: -40px;
  }

  .colorBlack {
    color: black;
  }

  .bottomHeaderr {
    background-color: #91D8F7;
    width: 100%;
    height: 480px;
    border-radius: 20px;
    margin-top: 13px;
  }

  .DeskContentDiv {
    padding-left: 20px;
    padding-right: 20px;
  }

  .blackDivBtns {
    width: 37px;
    margin-top: 10px;
  }

  .blackConnectTxt {
    margin-top: 15px;
    text-align: center;
    margin-left: 0px;
    font-size: 23px;
    color: black;
    font-weight: bold;
  }

  .hr {
    width: 99%;
    margin-top: 0px;
    border: 2px solid #0367FE !important;
    background-color: #0367FE;
  }

  .hrSnd {
    width: 100%;
    margin-top: 0px;
    border: 4px solid #0367FE !important;
    background-color: #0367FE;
  }

  .settingIcon {
    margin-left: -5px;
  }

  .colorGrey {
    color: #39393A;
    text-align: end;
    font-weight: bold;
  }

  .whiteDiv {
    background-color: white;
    color: black;
    border: 3px solid black;
    border-radius: 10px;
    padding: 7px 0;
  }

  .darkBlueDiv {
    background-color: #111224;
    border: 3px solid black;
    border-radius: 10px;
    padding: 7px 0;
  }

  .optionStakeBtn {
    background-color: #111224;
    border-radius: 10px;
    color: white;
    border: none;
    height: 100%;
    width: 80%;
    font-size: 16px;
    font-weight: bold;
  }

  .whiteStakeAmount h5 {
    font-weight: bold;
    text-align: end;
    color: #0367FE;
    font-size: 15px;
  }

  .extraFtpTxt h5 {
    font-weight: bold;
    text-align: start;
    color: #F4F401;
    font-size: 15px;
  }

  .MaxTxt {
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    margin-left: 7px;
  }

  .twelveMntTxt {
    margin-top: -10px;
  }

  .stakeBtns {
    background-color: #111224;
    border-radius: 14px;
    color: white;
    border: none;
    height: 48px;
    width: 180px;
    font-size: 17px;
    font-weight: bold;
  }

  .stakeNewBtn {
    width: 80% !important;
  }

  .hello {
    position: absolute;
    left: 18px;
  }

  .helloo {
    position: absolute;
    right: 18px;
  }

  .greyDivIstscreen {
    background-color: black !important;
  }

  .greyDivIstscreen h4 {
    font-size: 19px !important;
  }

  .greyDiv {
    /* background-color: #2B2B2B; */
    background-color: #262626;
    width: 95%;
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 10px;
    margin-top: 15px;
  }

  .greyDiv h4 {
    font-size: 20px;
  }

  .blackBottomBtn button {
    background-color: #2223DE;
    border: 3px solid rgb(0, 51, 154);
    color: white !important;
    height: 48px;
    width: 180px;
    font-weight: bold;
    font-size: 17px;
    border-radius: 14px;



    /* background-color: #111224;
    color: white;
    border: none;
    height: 40px;
    width: 150px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px !important; */

  }

  .foooterDiv {
    background-color: white;
    color: black;
  }

  .footerInerDiv {
    width: 71%;
    margin: 0 auto;
  }

  .footerInerDiv {
    padding-top: 50px;
    padding-bottom: 45px;
  }
  .footerInerDiv h2{
    margin-bottom: 12px;
    font-size: 22px;
  }

  .footerInerDiv .row .col-9 {
    padding-top: 20px;
    padding-left: 80px;
  }

  .exchnageBoxe {
    border-radius: 15px;
    width: 100%;
    /* background-color: red; */
    border: 2px solid #CBCBCB;
    margin-top: 20px;
  }

  .insideExchagneBox{ 
    margin-top: -7px;
    padding: 19px;
    padding-bottom: 37px;
  }

  .insideExchagneBox h5 {
    font-weight: bold;
    font-size: 23px;
    margin-bottom: 10px;
  }

  .singleBoxTxtLine {
    margin-bottom: 7px;
  }

  .exchangeBoxesBTN {
    background-color: #093399;
    color: white;
    border-radius: 5px;
    padding: 5px 24px;
    font-size: 22px;
    border: none;
    position: relative;
    top: 15px;
    margin-bottom: -6px;
  }

  .exchangeBoxImg{
    width: 101%;
    padding: -2px !important;
    margin: -2px !important;
    border-top-right-radius: 13px;
    border-top-left-radius: 13px;
  }

  .singleBoxTxtLine img {
    margin-right: 10px;
    margin-top: -7px;
    width: 10px;
  }

  .singleBoxTxtLine span {
    font-size: 20px;
    /* color: #383536; */
    color: rgb(43, 43, 43);
  }

  .singleBoxTxtLine span:hover {
    cursor: pointer ;
  }

  .ExchangeBoxes {
    /* width: 290px; */
    width: 100%;
    margin-top: 12px;
  }

  .footerDivCol9 h2 {
    color: #10559e;
    font-weight: bold;
    font-size: 22px;
    padding-bottom: 10px;
  }

  .footerDivCol9 h6 {
    color: #10559e;
    font-weight: bold;
    font-size: 17px;
    padding-bottom: 10px;
  }

  .footerDivCol9 p {
    color: #1d67b6;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .footerDivCol9 a {
    text-decoration: underline;
    color: rgb(86, 76, 221)
  }

  .footerBottom {
    background-color: #071144 !important;
    height: 330px;
    margin: 0 auto;
  }

  .footer_terms {
    width: 69.7%;
  }

  .hrOfBottom {
    width: 68%;
    margin: 0 auto;
  }

  .hrOfBottom p {
    font-size: 13px;
  }

  #footerBottomHR {
    border: 1px solid #1183B7 !important;
    width: 100%;
  }
}


@media (max-width: 1900px) {
  .navBarDisConnectBTn {
    color: #1DCBFE;
    margin-right: 21px;
    margin-left: 20px;
    font-size: 16px;
  }

  .navBarConnectBTn {
    color: #E0E102;
    margin-right: 24px;
    margin-left: 24px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .networksOptions {
    width: 175px !important;
  }

  .navBarNetworkBTn {
    color: #1DCBFE;
    font-weight: bold;
    margin-right: 31px;
    margin-left: 31px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .navBarDisNetworkBTn {
    color: #1DCBFE;
    font-weight: bold;
    margin-right: 11px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

}

@media (max-width: 1820px) {
  .navBarDisConnectBTn {
    margin-right: 16px;
    margin-left: 16px;
    font-size: 16px;
  }

  .navBarConnectBTn {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .networksOptions {
    width: 165px !important;
  }

  .navBarNetworkBTn {
    margin-right: 26px;
    margin-left: 26px;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .navBarDisNetworkBTn {
    margin-right: 7px;
    margin-left: 7px;
    font-size: 16px;
    position: relative;
    top: 2px;
  }
}

@media (min-width: 1800px) {
  .settingIcon {
    margin-left: 5px;
  }

  .bigScreenIssue {
    margin-left: 30px !important;
  }
}

@media (max-width: 1760px) {
  .navBarDisConnectBTn {
    margin-right: 13px;
    margin-left: 13px;
    font-size: 16px;
  }

  .navBarConnectBTn {
    margin-right: 17px;
    margin-left: 17px;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .networksOptions {
    width: 160px !important;
  }

  .navBarNetworkBTn {
    margin-right: 24px;
    margin-left: 24px;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .navBarDisNetworkBTn {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 16px;
    position: relative;
    top: 2px;
  }
}

@media (max-width: 1700px) {
  .navBarDisConnectBTn {
    margin-right: 14px;
    margin-left: 14px;
    font-size: 16px;
  }

  .navBarConnectBTn {
    margin-right: 14px;
    margin-left: 14px;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .networksOptions {
    width: 165px !important;
  }

  .navBarNetworkBTn {
    margin-right: 28px;
    margin-left: 28px;
    font-size: 15px;
    position: relative;
    top: 2px;
  }

  .navBarDisNetworkBTn {
    margin-right: 9px;
    margin-left: 9px;
    font-size: 15px;
    position: relative;
    top: 2px;
  }
}

@media (max-width: 1631px) {
  .navBarDisConnectBTn {
    margin-right: 8px;
    margin-left: 8px;
    font-size: 16px;
  }

  .navBarConnectBTn {
    margin-right: 8px;
    margin-left: 8px;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .networksOptions {
    width: 155px !important;
  }

  .navBarNetworkBTn {
    margin-right: 23px;
    margin-left: 23px;
    font-size: 15px;
    position: relative;
    top: 2px;
  }

  .navBarDisNetworkBTn {
    margin-right: 4px;
    margin-left: 4px;
    font-size: 15px;
    position: relative;
    top: 2px;
  }
}

@media (max-width: 1560px) {
  .navBarDisConnectBTn {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 15px;
  }

  .navBarConnectBTn {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 15px;
    position: relative;
    top: 2px;
  }

  .networksOptions {
    width: 148px !important;
  }

  .navBarNetworkBTn {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 14px;
    position: relative;
    top: 2px;
  }

  .navBarDisNetworkBTn {
    margin-right: 1px;
    margin-left: 1px;
    font-size: 14px;
    position: relative;
    top: 2px;
  }
}


@media only screen and (max-width: 1635px) and (min-width: 1000px) {
  .netwVidLAtokenTxtHeading {
    font-size: 17px !important;
  }
}

@media only screen and (max-width: 1558px) and (min-width: 1000px) {
  .greyDiv h4 {
    font-size: 19px;
  }

  .netwVidLAtokenTxtHeading {
    font-size: 16px !important;
  }

  .recipt1ConfirmTxt {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1000px) {
  #netwVidLAtokenTxtHeading {
    font-size: 14px !important;
  }

  .swapTknsHeadi {
    font-size: 16px !important;
  }

  .stakeNewBtn {
    width: 77% !important;
  }

  .graphPhoto {
    width: 430px;
    margin-top: 125px;
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1000px) {
  .greyDiv h4 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 1266px) and (min-width: 1000px) {
  .greyDiv h4 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1455px) and (min-width: 1000px) {
  .stakeBtns {
    height: 48px;
    width: 160px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 1393px) and (min-width: 1000px) {
  .optionStakeBtn {
    width: 100%;
  }

  .extraImg {
    width: 35px;
  }

  .stakeBtns {
    height: 48px;
    width: 150px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 1000px) {
  .stakeBtns {
    height: 48px;
    width: 140px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 1888px) and (min-width: 1000px) {
  .greyDivIstscreen h4 {
    font-size: 18px !important;
  }

  .istScreenHR {
    margin-top: 14px !important;
  }
}

@media only screen and (max-width: 1813px) and (min-width: 1000px) {
  .istScreenHR {
    margin-top: -7px !important;
  }

  .whichWalletMainSubDiv p {
    font-size: 19px !important;
  }
}

@media only screen and (max-width: 1755px) and (min-width: 1000px) {
  .sndScrenPDFheading2 {
    font-size: 17px !important;
  }
}

@media only screen and (max-width: 1575px) and (min-width: 1000px) {
  .greyDivIstscreen h4 {
    font-size: 16px !important;
  }

  .istScreenHR {
    margin-top: 10px !important;
  }

  .sndScrenPDFheading2, .sndScrenPDFheading3 {
    font-size: 16px !important;
  }

  .whichWalletMainSubDiv p {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1000px) {
  .greyDivIstscreen h4 {
    font-size: 17px !important;
  }

  .istScreenHR {
    margin-top: 4px !important;
  }
}

@media only screen and (max-width: 1418px) and (min-width: 1000px) {
  .greyDivIstscreen h4 {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 1360px) and (min-width: 1000px) {
  .greyDivIstscreen h4 {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1000px) {
  .istScreenHR {
    margin-top: -5px !important;
  }

  .MaxTxtt {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1227px) and (min-width: 1000px) {
  .greyDivIstscreen h4 {
    font-size: 17px !important;
  }

  .stakeNewBtn {
    width: 95% !important;
  }

  .helloo {
    position: absolute;
    right: 22px;
  }

  .graphPhoto {
    width: 380px;
    margin-top: 130px;
  }
}

@media only screen and (max-width: 1130px) and (min-width: 1000px) {
  .istScreenHR {
    margin-top: -15px !important;
  }

  .helloo {
    position: absolute;
    right: 24px;
  }

  .stakeNewBtn {
    width: 92% !important;
  }
}

@media (max-height: 712px) {
  .istScreenBtn {
    margin-top: -0px !important;
  }

  .istScreenBtn button {
    font-size: 16px !important;
  }
}





/* which wallet */

.whichWalletMainDiv, .whichWalletMainSubDiv {
  width: 95%;
  margin: 0 auto;
}

.whichWalletMainDiv p {
  font-size: 20px;
}

.whichWalletMainDiv p a {
  color: #18B2DF;
  text-decoration: underline;
  font-weight: bold;
}

.whichWalletBtn button {
  padding: 5px 30px !important;
  font-size: 20px;
  font-weight: bold;
  margin-top: 12px;
  border-radius: 14px !important;
}

.networkVideosBtn button {
  padding: 5px 30px !important;
  font-size: 17px;
  font-weight: bold;
  margin-top: -10px;
  border-radius: 14px !important;
}

.whichWalletMainSubDiv p {
  font-size: 21px;
}

@media only screen and (max-width: 1410px) and (min-width: 1000px) {
  .whichWalletMainSubDiv p {
    font-size: 19px;
  }

  .whichWalletHR {
    margin-top: 38px !important;
  }
}

@media only screen and (max-width: 1285px) and (min-width: 1000px) {
  .whichWalletMainSubDiv p {
    font-size: 18px;
  }

  .whichWalletHR {
    margin-top: 44px !important;
  }
}

@media (max-width: 500px) {
  .whichWalletMainSubDiv p {
    font-size: 15px;
    margin-top: -12px !important;
  }
}


/* Staked tokens */
@media (max-width: 390px) {
  .clickToRlsTxt {
    font-size: 14px !important;
  }
}

@media (max-width: 365px) {
  .clickToRlsTxt {
    font-size: 13px !important;
  }
}

@media (max-height: 675px) {
  .stakedTokensLink {
    margin-top: 5px !important;
    margin-bottom: -10px !important;
  }
}

.form-conrol {
  font-size: 15px !important;
  margin-bottom: -5px;
}

/* select Network */

.networksOptions {
  width: 182px;
  position: absolute;
  background-color: #00CCFF;
  margin-top: 2px;
  padding: 10px 5px 5px 10px;
  z-index: 99;
  border-radius: 6px;
  color: #000000;
}

.networksOptions h3 {
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.networkCross {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 16px;
  font-weight: bold;
  color: red;
  cursor: not-allowed;
}

@media (max-width: 1000px) {
  .networksOptions {
    width: 80px;
  }

  .fairtraderLogo {
    width: 120px;
  }

  .sndHeaderBtn img {
    width: 18px;
  }

  .headerBtns {
    font-size: 12px;
  }

  .toggleBtn {
    width: 35px;
  }

  .mobTokensImges {
    position: relative;
    top: -4px;
  }

  .mobTokensImgeNative img {
    width: 35px !important;
    top: -6px !important;
    left: 15px !important;
  }

  .assetsLockedFTP img {
    width: 36px !important;
  }

  .networksOptions {
    width: 85px !important;
  }

  .networksOptions h3 {
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }

  .graphPhoto {
    width: 50%;
    margin-top: 12px;
  }
}



/* Sign IN BOX */
.signInBox {
  /* background-color: red; */
  background-image: url(https://picsum.photos/id/1080/6858/4574), linear-gradient(rgb(219, 166, 166), rgb(0, 0, 172));
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.containersignInBox {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.boxsignInBox {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-family: sans-serif;
  text-align: center;
  line-height: 1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(20px);
  /* max-width: 50%;
  max-height: 50%; */
  height: 100%;
  width: 100%;
  padding: 20px 40px;
}

/* #top-div {
  margin: -8px;
  padding: 0;
  height: 98vh;
  background-color: #;
  color: rgb(68, 68, 68);
  font-family: Arial, Helvetica, sans-serif;
} */

#main-div {
  border-radius: 3px;
  width: 600px;
  margin: 60px auto;
  background: #071144;
  margin: 0px auto;
}

.second-div {
  border-radius: 8px;
  box-shadow: 0px 0px 0px 10px #e4e4e4;
  text-align: center;
  width: 600px;
  height: 650px;
  margin-top: 50px;
  /* height: auto; */
}

/* a {
  list-style: none;
  text-decoration: none;
  list-style-type: none;
  color: rgb(83, 83, 83);
  font-size: 18px;
} */

.lock-img {
  max-height: 190px;
  width: 500px;
  margin-top: 30px;
  /* margin-bottom: -90px; */
  height: 200px;
}

.lock-div {
  /* color: rgba(226, 10, 10, 0); */
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: bold;
}

.lock-subheading {
  font-weight: bold;
  font-size: 25px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.sign-in-sub {
  font-size: 14px;
  font-weight: bold;
  margin-top: -5px;
  color: rgb(204, 204, 204);
}

.input-fel {
  width: 350px;
  border: none;
  padding-bottom: 7px;
  border-bottom: 2px solid rgb(163, 163, 163);
  /* for moving of feild */
  transition-property: width;
  transition-duration: 1s;
  background: transparent;
  /* transition-delay: 1s; */
  height: 30px;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  background: transparent;
}

.setTokenPrice {
  width: 200px;
  /* for moving of feild */
  transition-property: width;
  transition-duration: 1s;
  background: transparent;
  /* transition-delay: 1s; */
  height: 30px;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  border: none;
  border-bottom: 2px solid white;
  color: #810580;
  background: transparent;
  border: 3px solid #810580;
  margin-bottom: 15px;
  padding-left: 10px;
}

.input-fel:hover {
  border-bottom: 2px solid white;
  color: white;
  /* for moving of feild */
  width: 230px;
}

.input-fel:active {
  border-bottom: 2px solid white;
  color: white;
  /* for moving of feild */
  width: 230px;
}

.input-fel:focus {
  outline: none;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  background: transparent;
}

.forgot-pass {
  font-size: 10px;
  margin-left: 65px;
  color: rgb(74, 0, 192);
  display: inline-block;
}

.forgot-passw {
  font-size: 10px;
  margin-right: 7px;
  color: rgb(74, 0, 192);
  display: inline-block;
}

.log-in {
  padding: 11px 29px;
  /* background-color: rgb(97, 50, 158); */
  background-color: #09993b;
  border: 1px solid rgb(185, 185, 185);
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  font-size: 18px;
}

.setTOkenPRiceBTN {
  padding: 2px 5px;
  font-size: 14px;
  margin-left: -38px;
  color: #810580;
  background: none !important;
  border-left: 3px solid #810580;
  border-radius: 0px;

}

.setTOkenPRiceBTN:hover {
  color: white !important;
  background: #09993b !important;
  border-left: 3px solid #09993b !important;
  border-radius: 0px !important;

}

.log-in-loader {
  padding: 11px 29px;
  /* background-color: rgb(97, 50, 158); */
  background-color: rgb(40, 0, 93);
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  font-size: 12px;
}

.log-in:hover {
  background-color: rgb(231, 231, 231);
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  border: 1px solid rgb(40, 0, 93);
}

@media screen and (max-width:680px) {
  #main-div, .second-div {
    width: 400px;
  }

  .lock-img {
    width: 300px;
  }
}

@media screen and (max-width:428px) {
  .assetsValueHeading {
    font-size: 14px !important;
  }
}

@media screen and (max-width:950px) {
  .graphPhoto {
    width: 50%;
    position: absolute;
    top: 10%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}

@media screen and (max-width:700px) {
  .graphPhoto {
    width: 70%;
    top: 10%;
  }
}



@media screen and (max-width:550px) {
  .graphPhoto {
    width: 70%;
    top: 12%;
  }
}

@media screen and (max-width:400px) {
  .graphPhoto {
    width: 70%;
    top: 13%;
  }
}

@media screen and (max-width:370px) {
  .graphPhoto {
    width: 65%;
    top: 10%;
  }
  .firstScreenMobBtn{
    margin-top: 12px !important;
    margin-bottom: 4px !important;
  }
  .stakeBtnss{
    font-size: 15px !important;
    height: 37px !important;
    width: 160px !important;
    margin-top: 8px !important;
    margin-bottom: -2px !important;
  }
  .stakePageStakeBTN{
    font-size: 14px !important;
    height: 35px !important;
    width: 150px !important;
    margin-top: 0px !important;
    margin-bottom: -5px !important;
  }
}
#myChart {
    margin: 0 auto;
    min-height: 150px;
    max-height: 150px;
    min-width: 95%;
    max-width: 95%;
}

.text-success {
    color: rgb(0, 255, 0) !important;
}

.graphPeriods {
    color: #18C7C7;
    font-size: 14px;
    font-weight: bold;
    margin-right: 30px;
    z-index: 9999;
}

.bottomHeader {
    width: 90%;
}

tspan {
    fill: white !important;
}
text {
    fill: white !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid #e3e3e3;
    background: rgb(24, 24, 24) !important;
}
.apexcharts-menu{
    background-color: black !important;
}
.apexcharts-menu-item:hover{
    background-color: rgb(37, 37, 37) !important;
}

.graphTimePeriod {
    margin-right: 10px;
}

.graphTimePeriod:active {
    background-color: transparent !important;
}

#tabChart, .showCoinDetail {
    display: none;
}
.graphSelectPeriod {
    margin-right: 20px;
}

.graphDetailCss {
    margin-bottom: -12px;
}

@media (max-width: 1000px) {
    #tabChart {
        display: initial;
    }

    #tabChart #chart {
        margin: 0 auto !important;
        margin-top: -25px !important;
    }

    #MobileChart {
        display: none;
    }

    #desktopChart {
        display: none;
    }

    .apexcharts-toolbar {
        display: none !important;
    }

    .graphCoinDetailRow {
        margin-top: -15px !important;
    }
    .graphDetailCss {
        position: absolute;
        right: -10px;
        /* margin-top: 40px; */
        /* right: 70px; */
    }
}


@media (max-width: 700px) {
    #tabChart #chart {
        position: relative;
        left: -5px;
    }
}


@media (max-width: 449px) {
    .graphCoinDetailRow {
        display: none;
    }

    .graphTOpContainer {
        margin-top: 15px;
    }

    #tabChart #chart {
        position: relative;
        left: 10px;
    }
    .graphDetailTxtSize {
        font-size: 14px !important;
    }

    .showCoinDetail {
        font-size: 14px;
        display: initial;
        background-color: #2D7EF7;
        border: 2px solid white;
        position: absolute;
        margin-top: -8px;
        margin-left: -2px;
        font-weight: bold;
        padding-top: 2px;
        border-bottom-right-radius: 14px;
        border-top-right-radius: 14px;
        z-index: 99;
    }
}

@media (max-width: 415px) {
    .showCoinDetail {
        font-size: 13px;
        padding-left: 1px;
    }

    .graphSelectPeriod {
        margin-right: 10px;
    }

}

@media (max-width: 380px) {
    .graphTOpContainer {
        margin-top: 5px !important;
    }

    .showCoinDetail {
        font-size: 12px;
        margin-top: -2px;
    }
}

@media (max-width: 520px) {
    .fontTwelve, .fontTwelveHeading {
        font-size: 12px;
    }

    #tabChart #chart {
        position: relative;
        left: -12px;
    }

    /* .graphPeriods{
        margin-right: 60px;
    } */
    .bottomHeader {
        width: 93% !important;
    }

}

@media (max-width: 470px) {
    .fontTwelve, .fontTwelveHeading {
        font-size: 11px;
    }
}

@media (max-width: 440px) {
    .fontTwelve, .fontTwelveHeading {
        font-size: 10px;
    }
}

@media (max-width: 400px) {
    .fontTwelve {
        font-size: 9px !important;
    }

    .fontTwelveHeading {
        font-size: 12px !important;
    }

    .graphFormats {
        font-size: 12px;
    }

    .graphSelectPeriod {
        font-size: 12px;
    }
}

@media (max-width: 365px) {
    .graphFormats {
        font-size: 11px;
    }

    .graphSelectPeriod {
        font-size: 11px;
    }

}

@media (max-width: 360px) {
    .fontTwelve {
        font-size: 8px !important;
    }

}

@media (max-height: 830px) {
    #myChart {
        min-height: 170px;
        max-height: 170px;
    }
}

@media (max-height: 790px) {
    #myChart {
        min-height: 160px;
        max-height: 160px;
    }
}

@media (max-height: 770px) {
    #myChart {
        min-height: 155px;
        max-height: 155px;
    }
}

@media (max-height: 750px) {
    #myChart {
        min-height: 145px;
        max-height: 145px;
    }
}

@media (max-height: 725px) {
    #myChart {
        min-height: 135px;
        max-height: 135px;
    }
}

@media (max-height: 700px) {
    #myChart {
        min-height: 125px;
        max-height: 125px;
    }
}

@media (max-height: 680px) {
    #myChart {
        min-height: 115px;
        max-height: 115px;
    }
}

@media (max-height: 655px) {
    #myChart {
        min-height: 110px;
        max-height: 110px;
    }
}

@media (min-width: 1000px) {
    #myChart {
        margin-top: -300px !important;
        /* position: relative;
        left: -50px; */
        min-height: 280px;
        max-height: 280px;
        min-width: 94%;
        max-width: 94%;
    }
}
@media (max-width: 400px) {
    .graphDetailTxtSize {
        font-size: 12px !important;
    }
}
input:focus, input:active, .form-control:focus, .form-control:active {
    outline: none !important;
    overflow: hidden;
}

.bottomHeaderrr {
    background-color: #91D8F7;
    width: 90%;
    margin: 0 auto;
    border-radius: 20px;
    margin-top: 15px;
    padding-bottom: 4px;
}

.DeskContentDivv {
    padding-left: 20px;
    padding-right: 20px;
}

.blackDivBtnss {
    width: 35px;
    margin-top: 9px;
}

.blackConnectTxtSwap {
    margin-top: 10px;
    text-align: center;
    margin-left: 0px;
    font-size: 21px;
    color: black;
    font-weight: bold;
    /* font-weight: 1000;
    letter-spacing: 1px; */
    position: relative;
    top: 5px;
}

.hrSwap {
    width: 100%;
    margin-top: 0px;
    border: 2px solid #0367FE;
    background-color: #0367FE;
}

.colorGreyy {
    color: black;
    text-align: end;
    font-size: 15px;
    font-weight: bold;
}

.whiteDivv {
    background-color: white;
    color: black;
    border: 3px solid black;
    border-radius: 10px;
    padding: 0px 0;
}

.darkBlueDivv {
    background-color: #182E2C;
    border: 3px solid #182E2C;
    border-radius: 10px;
    padding: 0px 0;
}

.optionStakeBtnn {
    background-color: #182E2C;
    border-radius: 10px;
    color: white;
    border: none;
    height: 80%;
    width: 70%;
    margin-top: 1px;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    top: -4px;
}

.whiteStakeAmountt h5 {
    font-weight: bold;
    text-align: end;
    font-size: 14px;
    margin-top: 7px;
}

input[type="email"]::-webkit-input-placeholder {

    /* Firefox, Chrome, Opera */
    color: #099933;
}

input[type="email"]::placeholder {

    /* Firefox, Chrome, Opera */
    color: #099933;
}


input[type="stakeTxt"]::-webkit-input-placeholder {

    /* Firefox, Chrome, Opera */
    color: #0367FE;
}


input[type="stakeTxt"]::placeholder {

    /* Firefox, Chrome, Opera */
    color: #0367FE;
}


input[type="hello"]::-webkit-input-placeholder {

    /* Firefox, Chrome, Opera */
    color: #1dccff;
}


input[type="hello"]::placeholder {

    /* Firefox, Chrome, Opera */
    color: #1dccff;
}

input[type="hello"]:-ms-input-placeholder {

    /* Internet Explorer 10-11 */
    color: #1dccff;
}

input[type="hello"]::-ms-input-placeholder {

    /* Microsoft Edge */
    color: #1dccff;
}

input, .form-control, .form-control:active, .form-control:focus {
    outline: none !important;
}

.swapSetAmount {
    text-align: end;
    font-weight: bold;
    color: #099933;
    border: none;
    font-size: 15px;
    border-top: none;
}

.extraFtpTxtt h5 {
    font-weight: bold;
    text-align: start;
    color: #F4F401;
    font-size: 12px;
    margin-top: 7px;
}

.MaxTxtt {
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    margin-left: 7px;
    position: relative;
    top: 15px;
}

.colorGreen {
    color: #099933;
}

.twelveMntTxtt {
    margin-top: -20px;
}

.stakeBtnss {
    background-color: #111224;
    border-radius: 10px;
    color: white;
    border: none;
    height: 40px;
    width: 160px;
    font-size: 16px;
    font-weight: bold;
}

/* receipt */

.wholePage {
    background-color: #91D8F7;
    /* width: 100%;
    height: 100vh; */
    overflow: hidden;
    /* margin-top: -10px; */
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.receiptMain {
    background-color: white;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    /* padding-top: 20px; */
    text-align: center;
}

.receiptHeading {
    background-color: #1761fb;
    padding-top: 20px;
    font-weight: bold;
    padding-bottom: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.fontSize {
    font-size: 15px;
}

.receiptBtn {
    background-color: #111224;
    border-radius: 10px;
    color: white;
    border: none;
    height: 40px;
    width: 160px;
    font-size: 16px;
    font-weight: bold;
}

.swapTOkenMargins {
    margin-left: 11px;
    margin-right: 11px;
}

.swapTokenTOken {
    margin-left: -10px;
}

.width30 {
    width: 30px;
}

.width40 {
    width: 40px;
}
.stakeBlance{
    font-size: 14px !important;
}

@media only screen and (max-width: 2000px) and (min-width: 1000px) {
    .swapTOkenMargins {
        margin-left: 5px;
        margin-right: 9px;
    }

    .swapTokenTOken {
        margin-left: -10px;
    }
}

@media only screen and (max-width: 1763px) and (min-width: 1000px) {
    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 8px;
    }

    .swapTokenTOken {
        margin-left: -10px;
    }
}

@media only screen and (max-width: 1550px) and (min-width: 1000px) {
    .settingIcon {
        margin-left: 0px;
    }

    .bigScreenIssue {
        margin-left: 15px !important;
    }
}

@media only screen and (max-width: 1700px) and (min-width: 1000px) {
    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 6px;
    }

    .width40 {
        width: 35px !important;
    }

    .swapTokenTOken {
        margin-left: -7px;
    }

    .headerNav, .MainDivMain, .footerInerDiv {
        width: 75%;
    }
}

@media only screen and (max-width: 1500px) and (min-width: 1000px) {
    .width40 {
        width: 30px !important;
    }

    .dropDOwn {
        width: 10px;
    }

    .swapTokenTOken {
        margin-left: -5px;
    }

    .headerNav, .MainDivMain, .footerInerDiv {
        width: 80%;
    }
}

@media only screen and (max-width: 1350px) and (min-width: 1000px) {
    .width40 {
        width: 25px !important;
    }

    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 4px;
        font-size: 13px;
    }

    .swapTokenTOken {
        margin-left: -2px;
    }

    .dropDOwn {
        width: 10px;
    }

    .swapAmountTxt {
        font-size: 13px !important;
    }
}

@media only screen and (max-width: 1300px) and (min-width: 1000px) {
    .swapOutput {
        width: 94px !important;
    }
}

@media only screen and (max-width: 1274px) and (min-width: 1000px) {
    .headerNav, .MainDivMain, .footerInerDiv {
        width: 85%;
    }
}

/* @media only screen and (max-width: 1403px) and (min-width: 1000px) {
    .width40 {
        width: 22px !important;
    }
    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 4px;
        font-size: 12px;
    }
    .dropDOwn {
        width: 8px;
    }
}

@media only screen and (max-width: 1365px) and (min-width: 1000px) {
    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 4px;
    }

    .recipt1ConfirmTxt {
        font-size: 17px;
    }

    .viewOnExplorerTxt {
        font-size: 18px !important;
    }

    .width40 {
        width: 35px !important;
    }
} */

@media only screen and (max-width: 1275px) and (min-width: 1000px) {
    .blackConnectTxtSwap {
        font-size: 22px !important;
    }
}

@media only screen and (max-width: 1227px) and (min-width: 1000px) {
    .width40 {
        width: 25px;
    }

    .graphDiv {
        width: 100% !important;
    }

    .functionalityDiv {
        width: 120% !important;
        margin-left: -70px;
    }

    .graphUpDown {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1033px) and (min-width: 1000px) {
    .swapTOkenMargins {
        margin-left: 0px;
        margin-right: 4px;
        font-size: 12px;
    }
}

/* @media (min-width: 1000px) {
    .receiptMain {
        background-color: #F2F2F2;
        width: 50%;
        margin-top: 60px;
        border: 50px solid white;
        border-radius: 10px;
    }
    .wholePage {
        background-color: #111224;
    }
} */
@media (max-width: 500px) {
    .swapTOkenMargins {
        margin-left: 4px;
        margin-right: 4px;
    }

    .hrSwap {
        border-width: 1px;
    }

    .navBarConnectBTn {
        font-weight: normal;
    }

    .swapAmountInput {
        background-color: transparent;
    }

    .swapAmountBox {
        margin-bottom: -5px;
    }
}

@media (min-width: 400px) {
    .marginMinus5 {
        margin-top: 5px !important;
    }
}

.assetsBottomHeandler {
    color: blue;
    margin-top: 26px;
}

.assetsActivityTxtActive {
    text-align: center;
    color: black;
    font-size: 18px;
    font-weight: bold;
}

#font18 {
    font-size: 18px !important;
}

@media (max-width: 400px) {
    .blackConnectTxtSwap {
        font-size: 19px;
    }

    .colorGreyy {
        font-size: 13px;
    }

    .balancE {
        margin-bottom: -1px;
    }

    .marginMinus5 {
        margin-top: 2px !important;
    }

    .assetsBottomHeandler {
        color: blue;
        margin-top: 15px;
    }

    .firstLightInfoMob {
        margin-top: 1px !important;
    }

    .blackDivBtnss {
        width: 30px;
        margin-top: 9px;
    }

    .hrSwap {
        margin-top: -7px;
    }

    .bottomBtns {
        margin-top: 7px !important;
    }

    .bottomBtns h1 {
        font-size: 16px !important;
    }
}

@media (max-width: 378px) {
    .dropDOwn {
        width: 10px;
    }

    .optionStakeBtnn {
        font-size: 13px;
    }

    .settingIcon {
        margin-left: -2px !important;
    }

}

@media (max-width: 357px) {
    .swapTOkenMargins {
        margin-left: 2px;
        margin-right: 2px;
    }
}
.greyDiv2 {
  background-color: black;
  width: 92%;
  margin: 0 auto;
  border-radius: 10px;
  padding-top: 8px;
  margin-top: 8px;
  text-align: left;
}

.greyDiv5 {
  background-color: black;
  width: 92%;
  margin: 0 auto;
  border-radius: 10px;
  padding-top: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.greyDiv5 h5 {
  font-size: 18px;
}

.greyDiv5 p {
  font-size: 11px;
}

.pdfheading {
  font-size: 15px;
}

.pdfPara {
  font-size: 11px;
}

.blackBottomBtn2 {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
  color: #1DCCFF;
}

.blackBottomBtn2 button {
  background-color: transparent;
  border: 3px solid #1DCCFF;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
}


.blackDivBtns2 {
  width: 33px;
  margin-top: 0px;
}

.blackConnectTxt2 {
  margin-top: 5px;
  text-align: center;
  margin-left: 0px;
  font-size: 18px;
  font-weight: bold;
  color: #1DCCFF;
}

.blueBlackMainDiv2 {
  background-color: black;
  margin: 0 auto;
  width: 85%;
  border-radius: 20px;
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 1px;
}

.blueBlackMainDiv3 {
  background-color: black;
  margin: 0 auto;
  width: 100%;
  border-radius: 20px;
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 1px;
}

.greyDiv2Desktop {
  background-color: black;
  width: 92%;
  margin: 0 auto;
  border-radius: 10px;
  padding-top: 15px;
  margin-top: 18px;
  margin-bottom: 20px;
  text-align: left;
}

.greyDiv2DeskAssets {
  background-color: #262626;
  width: 92%;
  margin: 0 auto;
  border-radius: 10px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  margin-top: 8px;
  text-align: center;
}

.greyDiv2DeskAssets h5 {
  font-size: 18px;
}
@media only screen and (max-width: 1450px) and (min-width: 1000px) {
  .pdfheading{
    font-size: 16px !important;
  }
  .sndScrenPDFheading2, .sndScrenPDFheading3{
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 1320px) and (min-width: 1000px) {
  .pdfheading{
    font-size: 15px !important;
  }
  .networdVideosHR{
    margin-top: 50px !important;
  }
  .sndScrenPDFheading2, .sndScrenPDFheading3{
    font-size: 14px !important;
  }
}
@media (max-width: 454px) {
  .pdfheading {
    font-size: 14px;
  }
}

@media (max-width: 427px) {
  .pdfheading {
    font-size: 13px;
  }

}


@media (max-width: 383px) {
  .pdfheading {
    font-size: 12px;
  }

  .pdfPara {
    font-size: 10px;
  }
}

@media (max-width: 356px) {
  .pdfheading {
    font-size: 11px;
  }

  .pdfPara {
    font-size: 9px;
  }
}

@media (min-width: 1000px) {
  .pdfheading {
    font-size: 18px;
  }

  .pdfPara {
    font-size: 12px;
  }
}
a:hover{
    text-decoration: none;
}

.blackConnectTxt3 {
    text-align: center;
    margin-left: 0px;
    font-size: 22px;
    font-weight: bold;
    color: #1DCCFF;
}

.greyDiv3 {
    /* background-color: #2B2B2B; */
    background-color: #111518;
    width: 90%;
    margin: 0 auto;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 1px;
    border-bottom: 1px solid #2B2B2B;
}

.greyDiv4 {
    /* background-color: #2B2B2B; */
    background-color: #111518;
    width: 90%;
    margin: 0 auto;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 1px;
    border-bottom: 1px solid #2B2B2B;
}

.WhichWalletPara {
    background-color: red;
}

.WhichWalletPara {
    font-size: 13px;
}

.bottomHeaderLogo1 {
    float: right;
    margin-right: -15px;
    margin-top: 8px;
}

.bottomHeaderLogo2 {
    float: right;
    margin-right: -15px;
    margin-top: -8px;
}

.marginLeft1 {
    margin-left: -5px;
}

.marginLeft2 {
    margin-left: -10px;
}

.marginRight {
    margin-right: -20px;
}

.fairtraderConnectWalletTxt {
    font-size: 16px;
}

@media (max-width: 400px) {
    .blackConnectTxt3 {
        font-size: 18px;
        margin-top: 1px;
        margin-bottom: 10px;
    }
}

@media (min-width: 1000px) {
    .greyDiv3 {
        background-color: #111518;
        width: 90%;
        margin: 0 auto;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 18px;
        padding-bottom: 18px;
        border-radius: 1px;
        border-bottom: 1px solid #2B2B2B;
    }
}
.networksDiv{
    border: 1px solid grey;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 12px;
    border-radius: 20px;
    padding-top: 12px;
    margin-top: 25px;
    font-size: 16px;
}
.selectNetworkHR{
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0.1% solid grey;
    background-color: grey;
}
.marginTop {
    margin-top: -6px;
}
.bottomBtns{
    text-align: center;
    margin-top: 7px;
    font-size: 18px;
    font-weight: bold;
    /* position: absolute;
    bottom: 2%; */
    color: #1FE2FA !important;
}
.networksDivDesktop{
    border: 1px solid grey;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;
    border-radius: 20px;
    padding-top: 20px;
    margin-top: 30px;
    font-size: 22px;
}
.txsTxt{
    font-size: 14px;
    font-weight: bold;
    color: #1FE2FA;
}
.txsL{
    margin-bottom: 5px;
    margin-top: 5px;
}
.recentTxTxt, .blackConnectTxt, .blackConnectTxtIstScreen{
    position: relative;
    top: 5px;
}


@media only screen and (max-width: 1850px) and (min-width: 1000px) {
    #recentTxTxt{
        font-size: 21px !important;
    }
    .headingOfNewPeriodSelct{
        font-size: 16px !important;
    }
}
@media only screen and (max-width: 1780px) and (min-width: 1000px) {
    #recentTxTxt{
        font-size: 20px !important;
    }
    .headingOfNewPeriodSelct{
        font-size: 15px !important;
    }
    .selctNewPeriodHR{
        margin-top: 15px !important;
    }
}
@media only screen and (max-width: 1600px) and (min-width: 1000px) {
    #recentTxTxt{
        font-size: 19px !important;
    }
}
@media only screen and (max-width: 1540px) and (min-width: 1000px) {
    #recentTxTxt{
        font-size: 18px !important;
    }.headingOfNewPeriodSelct{
        font-size: 14px !important;
    }
}
@media only screen and (max-width: 1386px) and (min-width: 1000px) {
    #recentTxTxt{
        font-size: 17px !important;
    }
    .headingOfNewPeriodSelct{
        font-size: 13px !important;
    }
}
@media only screen and (max-width: 1100px) and (min-width: 1000px) {
    .headingOfNewPeriodSelct{
        font-size: 14px !important;
    }
}
@media only screen and (max-width: 1275px) and (min-width: 1000px) {
    #recentTxTxt{
        font-size: 16px !important;
    }
}
@media only screen and (max-width: 1020px) and (min-width: 1000px) {
    .testStakeHeading{
        font-size: 18px !important;
        margin-left: 10px;
    }
    .testStakeSubHeading{
        font-size: 13px !important;
        margin-left: 10px;
    }
}
@media (max-width: 420px) {
    .recentTxTxt, .recentTxTxtt{
        font-size: 18px !important;
    }
}
@media (max-width: 400px) {
    .recentTxTxt, .recentTxTxtt{
        font-size: 17px !important;
    }
}
@media (max-width: 386px) {
    .recentTxTxt{
        font-size: 16px !important;
    }
     .recentTxTxtt{
        font-size: 16px !important;
    }
}
@media (max-width: 386px) {
    .recentTxTxt{
        font-size: 15px !important;
    }
}

@media (max-width: 360px) {
    .txsListHead{
    font-size: 17px !important;
}
}
@media (max-width: 341px) {
    .txsListHead{
    font-size: 16px !important;
}
}

@media (min-width: 1000px) {
    .txsTxt{
        font-size: 17px !important;
        font-weight: bold;
        color: #1FE2FA;
    }
}
.blackConnectTxt4 {
    font-size: 20px;
    margin-top: 3px;
    text-align: center;
    margin-left: 0px;
    font-weight: bold;
    /* color: #1DCCFF; */
    color: black;
}

.swapTknsHeadi {
    text-align: center;
    font-size: 15px;
    color: white;
    margin-top: 15px;
    margin-bottom: 18px;
}

.selectSwapToken {
    margin-top: 14px;
}

.swapTokens {
    margin-bottom: 10px;
}

@media (min-width: 1000px) {
    .swapTokens {
        margin-bottom: 17px;
    }
}

@media (max-width: 400px) {

    .selectSwapToken {
        margin-top: 20px;
    }

    .swapTokens {
        margin-bottom: 8px;
    }

    .swapTknsHeadi {
        font-size: 14px;
    }

    .blackConnectTxt4 {
        font-size: 19px;
    }
}

@media (max-width: 375px) {

    .selectSwapToken {
        margin-top: 10px;
    }

    .swapTknsHeadi {
        font-size: 14px;
    }

    .blackConnectTxt4 {
        font-size: 19px;
    }
}

@media (max-height: 687px) {
    .selectSwapToken{
        margin-top: 20px;
    }
}
@media (max-height: 667px) {
    .selectSwapToken{
        margin-top: 15px;
    }
}
@media (max-height: 657px) {
    .selectSwapToken{
        margin-top: 12px;
    }
}
  .amountStakeBtns {
      background-color: #111224;
      color: white;
      border: none;
      height: 40px;
      width: 150px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 20px !important;
  }

  .hrStack {
      width: 100%;
      margin-top: 0px;
      margin-bottom: 10px;
      border: 1px solid #0367FE;
      background-color: #0367FE;
  }

  .optionsOfStake {
      background-color: #111224;
      border-radius: 10px;
      color: white;
      border: none;
      height: 80%;
      width: 70%;
      margin-top: 6px;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      top: -4px;
  }

  .stakePeriodDropdown {
      position: relative;
      top: 0px;
      left: 4px;
      /* margin-left: -5px; */
  }

  .stakeExtraFtpTokenImg {
      margin-top: 4px;
      /* float: right;
      margin-right: -16px; */
  }

  @media only screen and (max-width: 1560px) and (min-width: 1000px) {
      .SelectStakePeriodContent {
          font-size: 13px;
          position: relative;
          left: 4px;
      }

      .stakePeriodDropdown {
          width: 24px;
      }
  }

  @media only screen and (max-width: 1400px) and (min-width: 1000px) {
      .SelectStakePeriodContent {
          font-size: 12px;
      }

      .stakePeriodDropdown {
          width: 22px;
      }

      .stakeExtraFtpTokenImg {
          margin-left: -5px;
      }
  }

  @media only screen and (max-width: 1335px) and (min-width: 1000px) {
      .SelectStakePeriodContent {
          font-size: 11px;
      }

      .stakePeriodDropdown {
          width: 18px;
          /* margin-top: 5px; */
      }
  }

  @media (max-width: 400px) {
      .perAnnum {
          margin-top: -19px !important;
      }

      .SelectStakePeriodContent {
          font-size: 12px;
      }

      .stakePeriodDropdown {
          width: 20px;
          margin-top: 5px;
      }
  }

  @media (max-width: 386px) {
      .perAnnum {
          margin-top: -19px !important;
      }

      .optionsOfStake {
          font-size: 13px;
      }

      .amountStakeBtns {
          height: 35px;
          width: 130px;
          font-size: 13px;
          /* margin-top: -5px; */
          margin-bottom: 5px;
      }

      .hrStack {
          margin-top: -7px;
      }
  }

  @media (max-width: 372px) {
      .perAnnum {
          margin-top: -19px !important;
      }

      .optionsOfStake {
          font-size: 12px;
      }

      .amountStakeBtns {
          height: 35px;
          width: 130px;
          font-size: 13px;
          margin-top: -5px;
          margin-bottom: 5px;
      }

      .hrStack {
          margin-top: -7px;
      }
  }

  @media (max-width: 372px) {

      .optionsOfStake {
          font-size: 11px;
      }

  }

  @media (max-width: 357px) {
      .SelectStakePeriodContent {
          font-size: 11px;
      }
  }
.networksDivv {
    border: 1px solid grey;
    width: 90%;
    background-color: #111224;
    margin: 0 auto;
    padding-bottom: 10px;
    border-radius: 20px;
    padding-top: 10px;
    margin-top: 25px;
    font-size: 16px;
    text-align: center;
}

.selectPeriodDIv {
    background-color: #182E2C !important;
}

.selectPeriodDIv h4 {
    font-weight: bold;
}

.selectPeriodHR {
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
    /* border: 0.1% solid grey; */
    /* border: 5px dotted grey; */
    /* border-style: dotted; */
    border-bottom: 2px dotted #4F5554;
    /* background-color: grey; */
}

.selectPeriodHead {
    font-size: 20px;
    text-align: center;
    margin-left: 0px;
    font-weight: bold;
    color: #1DCCFF;
}

/* SELECT NEW PERIOD */
.headingOfNewPeriodSelct {
    text-align: start;
    font-size: 17px;
    color: black;
    font-weight: bold;
    font-family: arial;
}

.networksDivvDesktop h4:hover, .twoMntStakeCrsrPointr {
    cursor: pointer;
}

@media only screen and (max-width: 1440px) and (min-width: 1000px) {
    .headingOfNewPeriodSelct {
        font-size: 15px;
    }

    .selctNewPeriodHR {
        margin-top: 15px !important;
    }
}

@media only screen and (max-width: 1282px) and (min-width: 1000px) {
    .selctNewPeriodHR {
        margin-top: 40px !important;
    }

    .selctNewPeriodROW {
        margin-bottom: 12px !important;
    }
}

@media only screen and (max-width: 1227px) and (min-width: 1000px) {
    .headingOfNewPeriodSelct {
        font-size: 14px;
    }

    .selctNewPeriodHR {
        margin-top: 25px !important;
    }
}

@media only screen and (max-width: 1079px) and (min-width: 1000px) {
    .selctNewPeriodHR {
        margin-top: 10px !important;
    }
}

@media (max-width: 384px) {
    .selectPeriodHead {
        font-size: 19px;
    }
}

@media (max-width: 365px) {
    .selectPeriodHead {
        font-size: 18px;
    }
}

@media (max-width: 367px) {
    .selctPeriodNewTxt {
        font-size: 13px !important;
    }
}

@media (min-width: 1000px) {
    .networksDivvDesktop {
        border: 1px solid grey;
        width: 90%;
        background-color: #111224;
        margin: 0 auto;
        padding-bottom: 10px;
        border-radius: 20px;
        padding-top: 10px;
        margin-top: 40px;
        margin-bottom: 25px;
        text-align: center;
    }

    .networksDivvDesktop h4 {
        font-size: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.networksDivvv {
    /* border: 1px solid grey; */
    width: 90%;
    /* background-color: #111224; */
    margin: 0 auto;
    padding-bottom: 10px;
    border-radius: 20px;
    padding-top: 10px;
    margin-top: 25px;
    font-size: 15px;
    text-align: start;
}

@media only screen and (max-width: 1703px) and (min-width: 1000px) {
    .whatIsStakingHR{
        margin-top: 72px !important;
    }
}
@media only screen and (max-width: 1535px) and (min-width: 1000px) {
    .whatIsStakingHR{
        margin-top: 43px !important;
    }
}
@media only screen and (max-width: 1281px) and (min-width: 1000px) {
    .whatIsStakingHR{
        margin-top: 16px !important;
    }
}
@media only screen and (max-width: 1227px) and (min-width: 1000px) {
    .whatIsStakingHR{
        margin-top: 40px !important;
    }
}
@media only screen and (max-width: 1084px) and (min-width: 1000px) {
    .whatIsStakingHR{
        margin-top: 16px !important;
    }
}

@media (max-width: 410px) {
    .networksDivvv {
        font-size: 14px;
    }
}@media (max-width: 380px) {
    .networksDivvv {
        font-size: 13px;
    }
}
